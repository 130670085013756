<template>
  <b-container>
    <bread-crumbs :currentpage="$t('myaccount')" />

    <b-row>
      <b-col cols="12" class="pb-0">
        <h1>{{ $t($route.name) }}</h1>
      </b-col>
    </b-row>

    <b-alert v-if="alertInfoMessage" show variant="danger">{{
      alertInfoMessage
    }}</b-alert>

    <div class="account-nav">
      <b-col class="d-none d-md-block">
        <div>
          {{ $t('account-generalDescription') }}
          <a :href="`/${this.$i18n.locale}`" class="float-right">{{
            $t('backToCatalog')
          }}</a>
        </div>
      </b-col>
      <div>
        <router-link
          v-for="page in pages"
          :key="page.name"
          :class="{
            btn: true,
            'btn-primary': $route.path.includes(page.path),
            'btn-secondary': !$route.path.includes(page.path),
            'btn-sm': true
          }"
          :to="`/${$i18n.locale}${page.path}`"
          >{{ page.name }}</router-link
        >
      </div>
    </div>

    <div>
      <router-view name="account" />
    </div>
  </b-container>
</template>
<script>
export default {
  metaInfo: {
    title: function() {
      return 'Mijn account'
    }
  },
  data() {
    return {
      pages: [
        {
          name: 'Mijn gegevens',
          path: '/account/user'
        },

        {
          name: 'Mijn Reserveringen',
          path: '/account/reservations'
        }
      ]
    }
  },
  computed: {
    metaTitle: function() {
      return this.$t('header.myAccount')
    },
    user: function() {
      return this.$store.getters.user
    },
    alertInfoMessage: function() {
      if (this.$route.query.message) {
        this.notify({ message: this.$route.query.message })
        return
      }
      if (this.$route.query.infoMessage) return this.$route.query.infoMessage
      return null
    }
  },
  created: function() {
    if (this.$route.params.loggedIn) {
      this.notify({ message: this.$t('login-success') })
    }
    if (this.$route.params.message) {
      this.notify({ message: this.$route.params.message })
    }
  },

  methods: {
    notify({ message }) {
      this.$toasted.show(message)
    }
  }
}
</script>
<style lang="scss" scoped>
.account-nav {
  margin-bottom: 20px;
}
</style>
